<template>
  <button @click="goBack" class="back-button"><img src="../../assets/img/back-button.svg" /></button>

</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  },
}
</script>

<style scoped>
button {
  background-color: var(--color-gray-300);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: none;
  height: 100%;
  }
</style>