<template>
  <div id="app" @click="clickOutSide()">

    <Nav @messageFromNav="messageFromNavReceived"/>
    <div class="mainContainer">
      <div v-if="isIntegration" style="position: fixed;width: 100%;height: 28px; left: 0; top: 0; z-index: 10000; background-color: orange;text-align: center; line-height: 28px;opacity: 0.8;pointer-events: none;">INTEGRATION STAGE</div>
      <b-alert
        :show="dismissCountDown"
        dismissible
        :variant="getAlert.alertVariant"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
      >
      {{getAlert.alertText}}
      <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress>
      </b-alert>
      <div v-if="isAuthenticated && venueChosen" class="topbar">
          <TopBarHeadline v-if="getShowTopBarHeadline" />
          <div></div>
          <div class="topbar-right">
            <a @click="resetChosenVenue">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g fill="#0CBA4A" fill-rule="nonzero">
                    <path d="M21 5h2v14h-2V5zm-4 0h2v14h-2V5zm-3 0H2c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-1 12H3V7h10v10z"/>
                    <path d="M11 11.233C11 9.34 9.675 8 8 8s-3 1.34-3 3.233c0 1.22.975 2.837 3 4.767 2.025-1.93 3-3.546 3-4.767z"/>
                </g>
            </svg>
            {{venueTitle}}
            </a>
            <a v-b-modal.modal-activities>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 3a9 9 0 0 0-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0 0 13 21a9 9 0 0 0 0-18zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8H12z" fill="#0CBA4A" fill-rule="nonzero"/>
              </svg>
            </a>
            <a v-b-modal.modal-settings>
              <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm5.724 13.347C13.437 11.781 10.314 11.25 9 11.25s-4.437.531-5.724 2.097A7.155 7.155 0 0 1 1.8 9c0-3.969 3.231-7.2 7.2-7.2 3.969 0 7.2 3.231 7.2 7.2a7.155 7.155 0 0 1-1.476 4.347zM9 4.5a3.366 3.366 0 0 0-3.375 3.375A3.366 3.366 0 0 0 9 11.25a3.366 3.366 0 0 0 3.375-3.375A3.366 3.366 0 0 0 9 4.5z" fill="#0CBA4A" fill-rule="nonzero"/>
              </svg>
            </a>
          </div>
          
          <!--
          <router-link to="">
             <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4c4.413 0 8 3.206 8 7.15 0 3.724-2.897 7.472-7.217 9.33L11.58 21v-2.71C7.36 18.096 4 14.966 4 11.15 4 7.205 7.587 4 12 4zm-.429 10.364a.85.85 0 0 0-.616.232.786.786 0 0 0-.24.591c0 .235.078.43.235.583.157.153.364.23.621.23.258 0 .465-.077.622-.23a.78.78 0 0 0 .236-.583.786.786 0 0 0-.241-.59.85.85 0 0 0-.617-.233zM12.03 7c-.932 0-1.668.229-2.208.687-.539.458-.813 1.092-.822 1.902h1.931c.01-.34.112-.605.31-.798.197-.193.46-.29.789-.29.693 0 1.039.359 1.039 1.075 0 .238-.067.463-.2.678-.133.214-.4.492-.802.833-.402.341-.679.688-.83 1.04-.15.352-.226.825-.226 1.418h1.316c.192 0 .355-.13.402-.308l.014-.08c.048-.415.241-.778.579-1.087l.54-.49c.421-.39.716-.745.885-1.066.169-.32.253-.66.253-1.02 0-.793-.26-1.407-.78-1.842C13.702 7.217 12.972 7 12.03 7z" fill="#0CBA4A" fill-rule="nonzero"/>
            </svg>
          </router-link>
          -->
         
          <!--
          <button @click="logout">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m16.333 8-1.292 1.41L16.489 11H9v2h7.49l-1.45 1.58L16.334 16 20 12l-3.667-4zM5.778 6.556H12V5H5.778C4.8 5 4 5.7 4 6.556v10.888C4 18.3 4.8 19 5.778 19H12v-1.556H5.778V6.556z" fill="#0CBA4A" fill-rule="nonzero"/>
              </svg>
          </button>
          -->
      </div>
      <ActivitiesModal ref="modal-activities"/>
      <b-modal ref="modal-settings" size="lg" id="modal-settings" :title="$t('settings.title')">
        <div v-if="saving==true" class="text-center">
           <LoadingAnimation />
        </div>
        <div v-else>
          <b-row class="settingsTopbar">
            <b-col>
              {{venueTitle}}
            </b-col>
            <b-col>
              <a style="cursor: pointer" @click="logout">
                <i18n path="settings.logout" >
                  <template v-slot:image>
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="m16.333 8-1.292 1.41L16.489 11H9v2h7.49l-1.45 1.58L16.334 16 20 12l-3.667-4zM5.778 6.556H12V5H5.778C4.8 5 4 5.7 4 6.556v10.888C4 18.3 4.8 19 5.778 19H12v-1.556H5.778V6.556z" fill="#0CBA4A" fill-rule="nonzero"/>
                    </svg>
                  </template>
                  <template v-slot:username>
                    {{getUser.username}}
                  </template>
                </i18n>
              </a>
            </b-col>
          </b-row>
          <div class="spacer l"></div>
          <b-row>
            <b-col>
              <h4>{{ $t('settings.glasperbottle')}}</h4>
            </b-col>  
          </b-row>
          <b-row v-if="getBottleSizes && getBottleSizes.length > 0" class="glasSettings">
            <b-row>
              
              <b-col cols="3">{{ $t('settings.bottles') }}</b-col>
              <b-col cols="2">{{ $t('settings.glassamount') }}</b-col>
              <b-col cols="1"></b-col>
              <b-col cols="3" v-if="getBottleSizes.length > 1">{{ $t('settings.bottles') }}</b-col>
              <b-col cols="2" v-if="getBottleSizes.length > 1">{{ $t('settings.glassamount') }}</b-col>
            </b-row>
            <div class="glasSettingsEntry" v-for="(element, index) in getBottleSizes" :key="index">
              <b-row>
                <b-col cols="6">{{element.attributes.amount}}</b-col>
                <b-col cols="5">
                  <b-form-input type="number" v-model="selectedGlassSize[element.id]" min="1"></b-form-input>
                </b-col>
              </b-row>  
            </div>
          </b-row>
          <div class="spacer l"></div>
          <b-row >
            <b-col>
              <h4>{{ $t('general.supplier')}}</h4>
            </b-col>
          </b-row>
          <b-row class="supplierRow">
            <b-col> 
              <label>{{ $t('general.name') }}</label>
            </b-col>
            <b-col>
             <label>{{ $t('general.zipcode') }}</label>
            </b-col>
            <b-col>
              <label>{{ $t('general.city') }}</label>
            </b-col>
            <b-col>
              <label>{{ $t('general.email') }}</label>
            </b-col>
            <b-col>
            </b-col>
          </b-row>
          <div v-for="supplier in filterNotDeletedWineSuppliers" :key="supplier.id">
            <b-row v-if="!supplier.attributes.isGeneral" class="supplierRow" >
              <b-col>
                <input v-model="supplier.attributes.title">
              </b-col>
              <b-col>
                <input v-if="supplier.attributes.address !== null" v-model="supplier.attributes.address.zip">
              </b-col>
              <b-col>
                <input v-if="supplier.attributes.address !== null" v-model="supplier.attributes.address.city">
              </b-col>
              <b-col>
                <input v-model="supplier.attributes.email">
              </b-col>
              <b-col>
                <button class="iconButton delete" @click="flagDeleteSupplier(supplier)">
                </button>
              </b-col>
            </b-row>
          </div>
          <b-row class="bezugsquelleRow">
            <b-col> 
                <button class="iconButton add" @click="addEmptySupplier()">
                    {{ $t('settings.addsupplier')}}
                </button>
            </b-col>
          </b-row>
          <div class="spacer l"></div>
          <b-row >
            <b-col>
              <h4> {{ $t('general.currencys')}}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-dropdown
                :text="selectedCurrency.shortname + ' - ' + selectedCurrency.name"
              >
                <b-dropdown-item
                  v-for="option in getCurrencies"
                  :key="option.attributes.name"
                  :value="option"
                  @click="currencyChanged(option)"
                >
                  {{ option.attributes.shortname }} - {{ option.attributes.name }}
                </b-dropdown-item>
              </b-dropdown>
              <!--
              <b-form-select v-model="getVenue.attributes.currency"  class="mb-3">
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Bitte eine Währung auswählen --</b-form-select-option>
                  <option v-bind:value="currency" v-for="(currency, cindex) in getCurrencies" :key="cindex">{{ currency.Shortname }} - {{ currency.Name }}</option>
                </template>
              </b-form-select>
              -->
           </b-col> 
          </b-row>
          <b-alert v-model="showInfoAlert.show" variant="warning" dismissible>
            {{showInfoAlert.message}}
          </b-alert>
          <b-alert v-model="showRedAlert.show" variant="danger" dismissible>
            {{showRedAlert.message}}
          </b-alert>
           <!-- Change language in Frontend deactivated, we decided Eren changes it in background, maybe later implementation
          <b-row >
            <b-col>
              <h4> {{ $t('general.language')}}</h4>
            </b-col>
          </b-row>
         
          <b-row v-if="getUser.language != null">
            <b-col cols="3">
              <b-dropdown 
                  :text="getUser.language.title"
              >
                  <b-dropdown-item v-for="locale in locales" :key="locale.title" :value="locale.languageKey" @click="switchLocale(locale)">
                    {{  $t('general.' + locale.languageKey) }}
                  </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          -->

        </div>
        <template class="poolinqModalFooter" #modal-footer="{ cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" variant="danger" @click="cancel()">
              {{$t('general.buttons.cancel')}}
            </b-button>
            <b-button size="sm" variant="success" @click="saveSuppliers()" :disabled="saving">
              {{$t('general.buttons.save')}}
            </b-button>
        </template>
      </b-modal>
      <router-view/>
      <PlaySmartListEditModal v-if="isAuthenticated && venueChosen" ref="playSmartListEditModal"/>
    </div>  
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import { mapGetters } from 'vuex'
import DataService from '@/services/StrapiService'
import PlaySmartListEditModal from '@/components/modals/PlaySmartListEditModal.vue'
import ActivitiesModal from '@/components/modals/ActivitiesModal.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import * as Utils from '@/utils/functions'
import TopBarHeadline from './components/inventory/TopBarHeadline.vue'

const CREATE_WINE_SUPPLIER = "create"
const DELETE_WINE_SUPPLIER = "delete"
const SAMMEL_LIEFERANT = "Sammellieferant"

export default {
  name: 'App',
  components: {
    Nav, PlaySmartListEditModal, LoadingAnimation, ActivitiesModal, TopBarHeadline
  },
  created () {
    if (this.getUser.language != null && this.getUser.language.languageKey != null) {
      this.$i18n.locale = this.getUser.language.languageKey
    } else {
      this.$i18n.locale = navigator.language || navigator.userLanguage
    }
  },
  mounted() {
    const app = this
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId == "modal-settings") {
        app.resetMessages()
        app.getWineSuppliers()
        app.setGlassAmounts()
        app.setCurrency()
      }
    });
    
  },
  data() {
    return {
      listname: '',
      wineSuppliers: [],
      showRedAlert: {
        show: false,
        message: "",
      },
      showInfoAlert: {
        show: false,
        message: ""
      },
      dismissSecs: 4,
      dismissCountDown: 0,
      dismissAlertText: 'Hallo',
      bottleSizes: [],
      selectedGlassSize: {},
      loading: true,
      saving: false,
      selectedCurrency: {
          name: this.$t("settings.selectCurrency"),
          shortname: "",
              },
      selectedCurrencyId: 0,
      isIntegration:DataService.isIntegrationServerOrLocalhost()
    }
  },
  methods: {
    setCurrency() {
      if (this.getVenue.attributes.currency) {
        this.selectedCurrency = this.getVenue.attributes.currency.data.attributes
        this.selectedCurrencyId = this.getVenue.attributes.currency.data.id
      }
    },
    resetChosenVenue() {
        this.$store.commit('updateVenue', {})
        this.$router.push('/venues')
    },
    resetMessages() {
      this.showRedAlert.message = ""
      this.showRedAlert.show = false
      this.showInfoAlert.message = ""
      this.showInfoAlert.show = false
    },
    getWineSuppliers() {
      if (this.getVenue) {
      this.wineSuppliers = JSON.parse(JSON.stringify(this.getVenue.attributes.wineSuppliers.data))
      }
    },
    refreshWineSuppliers() {
      this.wineSuppliers = JSON.parse(JSON.stringify(this.wineSuppliers))
    },
    /**
     * Adds an empty row of WineSupplier to edit
     */
    addEmptySupplier() {
      if (this.wineSuppliers) {
        this.wineSuppliers.push({
          id: null,
          attributes: {
            title: null, 
            email: null, 
            address: {zip: null, city: null},
            venues: {data: [this.getVenue.id]},
          },
          [CREATE_WINE_SUPPLIER]: true,
        })
     }
    },
    /**
     * Flag WineSupplier for delete. Count all VenueWines that will be moved to Sammellieferant and show info 
     */
    flagDeleteSupplier(supplier) {
      if (supplier.attributes.title === SAMMEL_LIEFERANT) {
        this.showRedAlert.message = "Sammellieferant kann nicht gelöscht werden"
        this.showRedAlert.show = true
        return
      }

      supplier[DELETE_WINE_SUPPLIER] = true

      const deleteWineSuppliers = this.wineSuppliers.filter(ws => ((DELETE_WINE_SUPPLIER in ws) && !(CREATE_WINE_SUPPLIER in ws)))
      const deleteWineSuppliersIds = deleteWineSuppliers.map(ws => ws.id)
      const deleteWineSuppliersTitles = deleteWineSuppliers.map(ws => ws.attributes.title)

      if (deleteWineSuppliers.length == 0) {
        this.refreshWineSuppliers()
        return
      }
      DataService.countVenueWinesOfWineSuppliers(deleteWineSuppliersIds).then((response) => {
        const affectedVenueWines = response['data'].data
        if (affectedVenueWines > 0) {
          this.showInfoAlert.message = 'Beim Löschen von ' + deleteWineSuppliersTitles.join() + ' werden die Preise von ' + response['data'].data + ' Weinen zum Sammellieferant übertragen'
          this.showInfoAlert.show = true
        }
        this.refreshWineSuppliers()
      }).catch((err) => {
        console.log(err)
      })

    },
    /**
     * Looks for newly added wine suppliers and creates them
     * Looks for delete-flagged WineSuppliers 
     * Looks for PurchasePrices of deleted WineSuppliers and adds them to Sammellieferant
     * Deletes WineSupplier
     */
    async saveSuppliers() {
      this.saving = true
      const newWineSuppliers = this.wineSuppliers.filter(ws => ((CREATE_WINE_SUPPLIER in ws) && !(DELETE_WINE_SUPPLIER in ws)))
      const otherWineSuppliers = this.wineSuppliers.filter(ws => (!(CREATE_WINE_SUPPLIER in ws) && !(DELETE_WINE_SUPPLIER in ws)))
      var createValid = true;
      var changeValid = true;
      var errorWasFound = false
      
      for (let i=0; i < otherWineSuppliers.length; i++) {
        const otherWineSupplier = otherWineSuppliers[i]
        if (otherWineSupplier.attributes.title != 'Sammellieferant' && 
            (otherWineSupplier.attributes.title == null)) {
          changeValid = false
          break
        }
      }
      if (!changeValid) {
        var alertobjectUpdate = {
              text: this.$t('settings.saveSupplierError'),
              variant: 'danger'
        }
        this.$store.commit('setAlertText', alertobjectUpdate)
        errorWasFound = true
        this.saving = false
        return
      }

      for (let i=0; i < otherWineSuppliers.length; i++) {
        const otherWineSupplier = otherWineSuppliers[i]
        await DataService.updateWineSupplier(otherWineSupplier.id, otherWineSupplier).then(() => {
        }).catch((err) => {
          var alertobject = {
              text: this.$t('settings.updateSupplierError'),
              variant: 'danger'
          }
          this.$store.commit('setAlertText', alertobject)
          errorWasFound = true
          console.log(err)
        })
      }
      

      for (let i=0; i < newWineSuppliers.length; i++) {
        const newWineSupplier = newWineSuppliers[i]
        if (!(newWineSupplier.attributes.title)) {
          createValid = false
          break
        }
      }
      if (!createValid) {
        var alertobjectValid = {
              text: this.$t('settings.saveSupplierError'),
              variant: 'danger'
        }
        this.$store.commit('setAlertText', alertobjectValid)
        errorWasFound = true
        this.saving = false
        return
      }

      for (let i=0; i < newWineSuppliers.length; i++) {
        const newWineSupplier = newWineSuppliers[i]
        await DataService.createWineSupplier(newWineSupplier).then(() => {
        }).catch((err) => {
          var alertobject = {
              text: 'Es gab einen Fehler beim Erstellen. Bitte Eingaben überprüfen!',
              variant: 'danger'
          }
          this.$store.commit('setAlertText', alertobject)
          console.log(err)
          errorWasFound = true
        })
      }

      await DataService.getVenueDetail().then((response) => {
        this.$store.commit('updateVenue', response['data'].data)
      }).catch((err) => {
        console.log(err)
      })

      const venue = Utils.deepCopyObject(this.getVenue)
     
      
      /**
       *  DELETE WINE-SUPPLIER:
       */
      const deleteWineSuppliers = this.wineSuppliers.filter(ws => ((DELETE_WINE_SUPPLIER in ws) && !(CREATE_WINE_SUPPLIER in ws)))
      for (const deleteWineSupplier of deleteWineSuppliers) {
       
        await DataService.deleteWineSupplier(deleteWineSupplier.id, venue.id).then(() => {
          venue.attributes.wineSuppliers.data = venue.attributes.wineSuppliers.data.filter(ws => ws.id !== deleteWineSupplier.id)
        }).catch((err) => {
          console.log(err)
        })
      }
      /**
       *  SET GLASS SIZES:
       */
      var glassSizes = []
      for (const keyBottleSizeId in this.selectedGlassSize) {
        var newGlassSizeObject = {
          bottleSizeId: Number(keyBottleSizeId),
          amount: this.selectedGlassSize[keyBottleSizeId]
        }
        if (this.selectedGlassSize[keyBottleSizeId] < 1) {
           var alertobject = {
            text: 'Die Anzahl kleiner als 1 bei Gläsern pro Flasche ist nicht erlaubt',
            variant: 'danger'
          }
          this.$store.commit('setAlertText', alertobject)
          delete this.selectedGlassSize.keyBottleSizeId
          errorWasFound = true
        } else {
          glassSizes.push(newGlassSizeObject)
        }
       
      }
      venue.attributes.currency = this.selectedCurrencyId
      venue.attributes.glassAmountPerBottle = glassSizes

      //delete unnecessary attributes
      delete venue.attributes.playlists
      delete venue.attributes.smartLists
      delete venue.attributes.reports
      delete venue.attributes.wineries
      delete venue.attributes.wineSuppliers

      await DataService.updateVenue(venue.id, venue).then((response) => {
        this.$store.commit('updateVenue', response['data'].data)
        this.getWineSuppliers()
      }).catch((err) => {
        errorWasFound = true
        console.log(err)
      })
      
      // update User temporary deactivated   
      /* await DataService.updateUserData(this.getUser).then((response) => {
        this.$store.commit('updateUserData', response.data)
      }).catch((err) => {
        errorWasFound = true
        console.log(err)
      }) */

      this.$store.commit('reloadWineSuppliers')
      this.resetMessages()
      this.saving = false
      if (!errorWasFound) {
        this.$refs["modal-settings"].hide();
      }
    },
    logout() {
      this.$router.push('/login')
      this.$store.dispatch('logout')
      this.$refs["modal-settings"].hide();
    },
    messageFromNavReceived(arg1) {
      if (arg1 === 'addPlaylist') {
        this.$refs.playSmartListEditModal.showModal()
      }
       
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
      if (dismissCountDown == 0) {
        var alertobject = {
            text: '',
            variant: 'info'
        }
        this.$store.commit('setAlertText', alertobject)
      }
    },
    setGlassAmounts() {
      var glassSizesArray = this.getVenue.attributes.glassAmountPerBottle
      if (glassSizesArray != null) {
        for ( var i = 0; i < glassSizesArray.length; i++ ) {
          this.selectedGlassSize[glassSizesArray[i].bottleSizeId] = glassSizesArray[i].amount
        }
      }
    },
    generateOptions(bottleSize) {
      var returnArray = []
      var length = bottleSize * 8
      for (var i=0;  i< length; i++) {
        returnArray.push(i)
      }
      return returnArray
    },
    clickOutSide() {
      this.$store.commit('clickedOutside')
    },
    goTo(link) {
      if (link) {
        this.$router.push(link)
        
      }
    },
    currencyChanged (option) {
      this.selectedCurrency = option.attributes;
      this.selectedCurrencyId = option.id
      var venue = this.getVenue
      venue.attributes.currency.data = option
      this.$store.commit('updateVenue', venue)
    }
    /* change language deactivated
    switchLocale(locale) {
      if (this.$i18n.locale !== locale.languageKey) {
        this.$i18n.locale = locale.languageKey
        var user = this.getUser
        user.language = locale
        this.$store.commit('updateUserData', user)
      }

    }
    */
  },
  computed: {
      venueTitle: function () {
        return this.getVenue.attributes != null ? this.getVenue.attributes.title : ''
      },
      loggedIn: function () {
          return this.getUser.username != null
      },
      filterNotDeletedWineSuppliers() {
        return this.wineSuppliers.filter(ws => !(DELETE_WINE_SUPPLIER in ws))
      },
      ...mapGetters(['isAuthenticated', 'venueChosen', 'getUser', 'getVenue', 'getShowPlaylistPopup', 'getAlert', 'getBottleSizes', 'getCurrencies', 'getLocales', 'getShowTopBarHeadline'])
  },
  watch: {
    getAlert: {
        handler(to){
            if (to.alertText != '') {
              this.dismissCountDown = this.dismissSecs
            }
        }, deep: true
    }
  }
}
</script>