<template>
    <nav v-if="isAuthenticated && venueChosen && !isInventoryOnly" class="navigationBar">
        <a class="navigationBar-brand" @click="resetChosenVenue"><img src="../assets/img/logo_pooling.svg"></a>
        <ul class="mainNavigation">
            <li>
                <router-link to="/">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 3v8h8V3H3zm6 6H5V5h4v4zm-6 4v8h8v-8H3zm6 6H5v-4h4v4zm4-16v8h8V3h-8zm6 6h-4V5h4v4zm-6 4v8h8v-8h-8z" fill="#0CBA4A" fill-rule="nonzero"/>
                    </svg>
                    <span>{{ $t('navigation.dashboard') }}</span>
                </router-link>
            </li>
            <!-- <li><router-link to="/register" v-if="!isAuthenticated">SIGN UP</router-link></li> -->
            <!-- <li><router-link to="/login" v-if="!isAuthenticated">LOGIN</router-link></li> -->
            <li>
                <router-link to="/wines" v-if="isAuthenticated">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M0 0h24v24H0z"/>
                            <path d="M3 14c0 1.3.84 2.4 2 2.82V20H3v2h6v-2H7v-3.18C8.16 16.4 9 15.3 9 14V6H3v8zm2-3h2v2H5v-2zm0 2h2v1c0 .55-.45 1-1 1s-1-.45-1-1v-1zM20.64 8.54l-.96-.32a1 1 0 0 1-.68-.95V3c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v4.28a1 1 0 0 1-.68.95l-.96.32c-.81.28-1.36 1.04-1.36 1.9V20c0 1.1.9 2 2 2h7c1.1 0 2-.9 2-2v-9.56c0-.86-.55-1.62-1.36-1.9zM19 19h-5v-7h5v7z" fill="#0CBA4A" fill-rule="nonzero"/>
                        </g>
                    </svg>
                    <span>{{ $t('navigation.winelist') }}</span>
                </router-link>
            </li>
            <li>
                <router-link to="/insights" v-if="isAuthenticated">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <circle fill="#0CBA4A" cx="2.5" cy="18.5" r="2.5"/>
                            <circle fill="#0CBA4A" cx="9.5" cy="14.5" r="2.5"/>
                            <circle fill="#0CBA4A" cx="15.5" cy="13.5" r="2.5"/>
                            <circle fill="#0CBA4A" cx="21.5" cy="6.5" r="2.5"/>
                            <path stroke="#0CBA4A" stroke-linecap="square" d="m2.5 18.5 7-4 6-.9 6-7.1"/>
                        </g>
                    </svg>
                    <span>{{ $t('navigation.insights') }}</span>
                </router-link>
            </li>
            <li>
                <router-link to="/reports" v-if="isAuthenticated">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M0 0h24v24H0z"/>
                            <path d="M15 3H5c-1.1 0-1.99.9-1.99 2L3 19c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V9l-6-6zM5 19V5h9v5h5v9H5zM9 8c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1zm0 4c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1zm0 4c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1z" fill="#0CBA4A" fill-rule="nonzero"/>
                        </g>
                    </svg>
                    <span>{{ $t('navigation.reports') }}</span>
                </router-link>
            </li>
            <li>
                <router-link to="/winecard" v-if="isAuthenticated">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M0 0h24v24H0z"/>
                            <path d="M21 5h2v14h-2V5zm-4 0h2v14h-2V5zm-3 0H2c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-1 12H3V7h10v10z" fill="#0CBA4A" fill-rule="nonzero"/>
                            <path d="m9.505 10.616-.349-.128a.4.4 0 0 1-.247-.38V8.4c0-.22-.164-.4-.364-.4h-1.09c-.2 0-.364.18-.364.4v1.712a.4.4 0 0 1-.247.38l-.35.128c-.294.112-.494.416-.494.76v3.82c0 .44.327.8.727.8h2.546c.4 0 .727-.36.727-.8v-3.824c0-.344-.2-.648-.495-.76zM9 15H7v-3h2v3z" fill="#0CBA4A" fill-rule="nonzero"/>
                        </g>
                    </svg>
                    <span>{{ $t('navigation.winecard') }}</span>
                </router-link>
            </li>
            <li>
                <router-link to="/inventories" v-if="isAuthenticated">
                   <img src="../assets/img/inventory.svg">
                    <span>{{ $t('navigation.inventory') }}</span>
                </router-link>
            </li>
            <!-- <li><router-link to="" v-if="isAuthenticated">User: {{ getUser.username }}</router-link></li> -->
            <!-- <li><router-link to="" v-if="isAuthenticated && venueChosen">Venue: {{ getVenue.attributes.title }}</router-link></li> --> 
        </ul>
        <div class="listContainer">
            <ul class="playListsContainer">
                <li v-if="hasPlaylist">
                    <Container @mouseenter.native="setChoosenPlaylist(-2)" group-name="playlistDrop" behaviour="drop-zone" class="dropzone deleteFromList" @drop="onDrop($event, null)">
                        <span>{{ $t('navigation.deleteWineFromList') }}</span>
                    </Container>
                </li>
            </ul>    
            <ul v-if="hasSmartList" class="smartListsContainer">
                <li :class="isFavorites(smartList.attributes.type) + ' ' + isActiveSmartList(smartList.id)" v-for="smartList in getVenue.attributes.smartLists.data" :key="smartList.id" @click="showSmartList(smartList)">
                    <span v-if="smartList.attributes.type === 'favorite'">{{ $t('navigation.smartListFavorites') }}</span>
                    <span v-else>{{smartList.attributes.title}}</span>
                </li>
            </ul>
            <ul class="playListsContainer">
                <li>
                    <Container @mouseenter.native="setChoosenPlaylist(element.id)" v-for="(element, key) in getVenue.attributes.playlists.data" group-name="playlistDrop" :class="'dropzone ' + isActiveList(element.id)" @drop="onDrop($event, element.id)" :key="key">
                        <span @click="showPlaylist(element.id)" >{{element.attributes.title}}</span>
                    </Container>
                </li>
            </ul>
            <!--
            <Container group-name="playlistDrop" class="dropzone" @drop="onDrop($event, 'playlist2')">
                <span @mouseenter="setChoosenPlaylist('playlist2')">Playlist 2</span>
            </Container>
            -->
        </div>  
        <b-button class="addPlaylist" @click="addPlaylist()">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h24v24H0z"/>
                    <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="#0CBA4A" fill-rule="nonzero"/>
                </g>
            </svg>
            <span>{{ $t('navigation.createNewList') }}</span>
        </b-button>
    </nav>
</template>
<script>
    //import DataService from '@/services/StrapiService'
    import { mapGetters } from 'vuex'
    import { Container } from "vue-dndrop"
    import DataService from '@/services/StrapiService'

    export default {
        name: 'Nav',
        components: {
            Container
         },
        data() {
            return {
                choosenPlaylist: ""
            }
        },
        computed: {
            path: function () {
                return this.$router.currentRoute.path
            },
            loggedIn: function () {
                return this.getUser.username != null
            },
            hasPlaylist: function () {
                return this.getFilter.playlist != null
            },
            hasSmartList: function() {
                return !this.isInventoryOnly && this.getVenue.attributes != null 
                && this.getVenue.attributes.smartLists.data != null 
                && this.getVenue.attributes.smartLists.data.length > 0
            },
            ...mapGetters(['isAuthenticated', 'venueChosen', 'getUser', 'getVenue', 'getFilter', 'getReloadVenue','isInventoryOnly']),
        },
        created () {
        },
        methods: {
            onDrop(dropResult, playlistId) {
                if (this.choosenPlaylist === -2) {
                  this.choosenPlaylist = -1 // reset to not fire more delete events
                    DataService.deleteVenueWineFromPlaylist(dropResult.element.id, this.getFilter.playlist).then(() => {
                        var alertobject = {
                            text: this.$t('navigation.wineDeletedFromList'),
                            variant: 'danger'
                        }
                        this.$store.commit('setAlertText', alertobject)
                        this.$store.commit('reloadWines')
                    }).catch((err) => {
                        console.log(err)
                    })
                } else if ( this.choosenPlaylist === playlistId ) {
                    DataService.getPlaylist(playlistId).then((response) => {
                        var playlist = response['data'].data
                        var found = false
                        playlist.attributes.venueWines.data.forEach(wine => {
                          if (wine.attributes.wine.data.id == dropResult.element.id) {
                            found = true
                          }
                        });
                        if (found) {
                          var alertobject = {
                              text: this.$t('navigation.wineAlreadyInList'),
                              variant: 'danger'
                          }
                          this.$store.commit('setAlertText', alertobject)
                        } else {
                            playlist.attributes.venueWines.data.push(dropResult.element.id)

                            DataService.updatePlaylist(playlistId, playlist).then(() => {
                                this.$store.commit('reloadVenue')
                                var alertobject = {
                                    text: this.$t('navigation.wineAddedToList'),
                                    variant: 'info'
                                }
                                this.$store.commit('setAlertText', alertobject)
                          }).catch((err) => {
                              console.log(err)
                          })
                        }
                       
                    }).catch((err) => {
                        console.log(err)
                    })
                } 
            },
            onDropDelete(dropResult) {
                DataService.deleteVenueWineFromPlaylist(dropResult.element.id, this.getFilter.playlist).then(() => {
                        var alertobject = {
                            text: this.$t('navigation.wineDeletedFromList'),
                            variant: 'danger'
                        }
                        this.$store.commit('setAlertText', alertobject)
                        this.$store.commit('reloadWines')
                    }).catch((err) => {
                        console.log(err)
                    })
            },
            setChoosenPlaylist(playlist) {
                this.choosenPlaylist = playlist
            },
            resetChosenVenue() {
                this.$store.commit('updateVenue', {})
                this.$router.push('/venues')
                // this.$router.push('/')
            },
            addPlaylist () {
                this.$emit('messageFromNav', 'addPlaylist')
            },
            showPlaylist (id) {
                console.log(id)
                var filter = { playlist : id}
                this.$store.commit('updateFilter', filter)
                this.$router.push({ name: 'Wines', query: { playlistId: id.toString() } })
            },
            showSmartList (smartList) {
                var filter = { smartList : smartList.id }
                this.$store.commit('updateFilter', filter)
                this.$router.push({ name: 'Wines', query: { smartListId: smartList.id.toString() } })
            },
            isFavorites (smartListType) {
                // Hier muss noch Lokalisierung eingebaut werden! Evtl. Mechanik überdenken.
                if( smartListType === "favorite" ) {
                    return "favorits"
                }
            },
            isActiveList (listId) {
                var playlistId = this.$route.query.playlistId
                if( playlistId == listId ) {
                    return "active"
                }
            },
            isActiveSmartList (listId) {   
                var smartListId = this.$route.query.smartListId
                if( smartListId == listId ) {
                    return "active"
                }
            },
        },
        watch:{
            '$route' (to){
                if (to.name !== 'Wines') {
                    var filter = { }
                    this.$store.commit('updateFilter', filter)
                }
            },
            getReloadVenue() {
                DataService.getVenueDetail().then((response) => {
                    this.$store.commit('updateVenue', response['data'].data)
                }).catch((err) => {
                    console.log(err)
                })
            },
        },
    }
</script>
<style scoped>
</style>
            