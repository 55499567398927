import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vuex from 'vuex';
import axios from 'axios'
import vueAxios from 'vue-axios'
import VuePapaParse from 'vue-papa-parse'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';


// Custom Css
//import "@/assets/scss/main.scss"
import "@/assets/css/main.css"
import VueCompositionAPI from '@vue/composition-api'
import { Chart, registerables } from "chart.js";
import i18n from './i18n'
Chart.register(...registerables);

Vue.use(VueCompositionAPI)

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(vueAxios, axios)
Vue.use(Vuex);
Vue.use(VuePapaParse)

Vue.config.productionTip = false

// global auto logout function
var timeout
var minutes = 600

// jeder Klick sorgt für eine Logout-Überprüfung
refresh(true)
document.addEventListener('click', refresh)

function refresh (firstLoad) {

 
  if (firstLoad == true && Date.now() - localStorage.getItem('lastclick', Date.now()) > minutes * 60 * 1000) {
    localStorage.setItem('lastclick', Date.now())
    if (store.getters.isAuthenticated) {
      store.dispatch('logout')
      router.push('/login')
    }
  }
  localStorage.setItem('lastclick', Date.now())
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    if (store.getters.isAuthenticated) {
      store.dispatch('logout')
      router.push('/login')
    }
  }, minutes * 60 * 1000)
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
