export default {

  getPeriodOptions(renderYear) {
    let options = 
      ["today",
      "last7days",
      "last14days",
      "thisMonth",
      "lastMonth",
      "thisQuarter",
      "lastQuarter"]
    if (renderYear) {
      options.push("thisYear")
    }
    return options 
  },

  getPeriodValues() {
    return {
      "today": {
        from: this.getStartOfToday(),
        previousFrom: this.getYesterday(),
        to: this.getEndOfToday()
      },
      "last7days": {
        from: this.getLast7Days(),
        previousFrom: this.getLastTwoWeeks(),
        to: this.getEndOfToday()
      },
      "last14days": {
        from: this.getLastTwoWeeks(),
        previousFrom: this.getLastFourWeeks(),
        to: this.getEndOfToday()
      },
      "thisMonth": {
        from: this.getCurrentMonth(),
        previousFrom: this.getLastMonthStart(),
        to: this.getEndOfToday()
      },
      "lastMonth": {
        from: this.getLastMonthStart(),
        previousFrom: this.getMonthBeforeLastMonthStart(),    
        to: this.getLastMonthEnd(),
      },
      "thisQuarter": {
        from: this.getCurrentQuarter(),
        previousFrom: this.getLastQuarterStart(),
        to: this.getEndOfToday()
      },
      "lastQuarter": {
        from: this.getLastQuarterStart(),
        previousFrom: this.getQuarterBeforeLastQuarterStart(),
        to: this.getEndLastQuarter(),
      },
      "thisYear": {
        from: this.getThisYearStart(),
        previousFrom: this.getLastYearStart(),
        to: this.getEndOfToday(),
      }
    }
  },
  dateToFileString(date) {
    var dateString =  date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    return dateString
  },

  dateToString(date, end) {
    var dateString =  date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    let timeZoneOffset = this.getTimezoneOffset(date)

      if (end == true)
      { 
        return dateString +  "T23:59:59" + timeZoneOffset;
      } //+02:00 Z
      else {
        return dateString +  "T00:00:01" + timeZoneOffset;
      }
  },
  dateToFormattedstring(date, time, locale) {
    var dateString = date.toLocaleDateString(locale, {year: 'numeric', month: '2-digit', day: '2-digit'});
    if(time === true) {
      dateString +=  " " + date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', hour12: false });
    }
    return dateString
  },
  getStartOfToday() {
    const date = new Date();
    const dateString = this.dateToString(date, false)
    return dateString
  },
  getYesterday() {
    const date = new Date();
    const yesterday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    const dateString = this.dateToString(yesterday, false)
    return dateString
  },
  getEndOfToday() {
    const date = new Date();
    const dateString = this.dateToString(date, true)
    return dateString
  },
  getLast7Days() {
    const date = new Date();
    var lastWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
    const dateString = this.dateToString(lastWeek, false)
    return dateString
  },
  getLastTwoWeeks() {
    const date = new Date();
    var lastTwoWeeks = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 14);
    const dateString = this.dateToString(lastTwoWeeks, false)
    return dateString
  },
  getLastFourWeeks() {
    const date = new Date();
    var lastFourWeeks = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28);
    const dateString = this.dateToString(lastFourWeeks, false)
    return dateString
  },
  getCurrentMonth() {
    const date = new Date();
    var thisMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dateString = this.dateToString(thisMonth, false)
    return dateString
  },
  getLastMonthStart() {
    const today = new Date();
    var month = Math.floor((today.getMonth() -1));
    var year = today.getFullYear()
    if ( today.getMonth() === 0 || today.getMonth() === "0") {
      month = 11;
      year = year - 1
    }
    const startMonth = new Date(year, month, 1);
    const dateString = this.dateToString(startMonth, false)
    return dateString
  },
  getMonthBeforeLastMonthStart() {
    const today = new Date();
    var month = Math.floor((today.getMonth() -2));
    var year = today.getFullYear()
    if ( today.getMonth() === 0 || today.getMonth() === "0") {
      month = 11;
      year = year - 1
    }
    const startMonth = new Date(year, month, 1);
    const dateString = this.dateToString(startMonth, false)
    return dateString
  },
  getLastMonthEnd() {
    const today = new Date();
    const month = Math.floor(today.getMonth());
    const endMonth = new Date(today.getFullYear(), month, 0);
    const dateString = this.dateToString(endMonth, true)
    return dateString
  },
  getCurrentQuarter() {
    const today = new Date();
    const quarter = Math.floor((today.getMonth() / 3));
    const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
    const dateString = this.dateToString(startFullQuarter, false)
    return dateString
  },
  getLastQuarterStart() {
    const today = new Date();
    var year = today.getFullYear();
    var quarter = Math.floor((today.getMonth() / 3)); // Dez = 3, Jan = 0
    if ( quarter === 0) {
      year = year - 1
      quarter = 4
    }
    const startFullQuarter = new Date(year, quarter * 3 - 3, 1);
    const dateString = this.dateToString(startFullQuarter, false)
    return dateString
  },
getQuarterBeforeLastQuarterStart() {
    const today = new Date();
    var year = today.getFullYear();
    var quarter = Math.floor((today.getMonth() / 3)); // Dez = 3, Jan = 0 April = 1
    if ( quarter === 0 || quarter === 1) {
      year = year - 1
      quarter = 4 + quarter
    }
    const startFullQuarter = new Date(year, quarter * 3 - 6, 1);
    const dateString = this.dateToString(startFullQuarter, false)
    return dateString
  },
  getEndLastQuarter() {
    const today = new Date();
    var year = today.getFullYear();
    var quarter = Math.floor((today.getMonth() / 3));
    if ( today.getMonth() === 0 || today.getMonth() === 1 || today.getMonth() === 2) {
      year = year - 1
      quarter = 11/3
    }
    const startFullQuarter = new Date(year, quarter * 3 - 3, 1);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
    const dateString = this.dateToString(endFullQuarter, true)
    return dateString
  },
  getThisYearStart() {
    const today = new Date();
    const startYear = new Date(today.getFullYear(), 0, 1);
    const dateString = this.dateToString(startYear, false)
    return dateString
  },
  getLastYearStart() {
    const today = new Date();
    const startYear = new Date(today.getFullYear() - 1, 0, 1);
    const dateString = this.dateToString(startYear, false)
    return dateString
  },
  getTimezoneOffset(date) {
    /*
    Returns the current TimeZone Offset String like “+02:00“
    Daylight savings time differences are being calculated automatically 
    */
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };
      
    return dif + pad(Math.floor(Math.abs(tzo) / 60)) + ':' + pad(Math.abs(tzo) % 60);
    
  }
}
