const qs = require('qs');
const dot = require('dot-object');

const AND_FILTER = [
	'purchasePriceAvg', 'sellingPrice'
]

export default {

	mapFilterAndOrRowToObj(filterAndOrRow, tempFilterFieldsLength) {
		const dottedFilterRowObj = dot.dot(filterAndOrRow);

    const dottedFilterRowKey = Object.keys(dottedFilterRowObj)[0]

		var dottedFilterRowKeyArr = dottedFilterRowKey.split('.')

		const selectedOperator = dottedFilterRowKeyArr.pop()

		const selectedValue = Object.values(dottedFilterRowObj)[0]

		const selectedFilter = dottedFilterRowKeyArr.join('.')

		const index = tempFilterFieldsLength + 1

		return {
			index: index,
			filter: selectedFilter,
			operator: selectedOperator,
			value: selectedValue
		}
	},

	mapFilterRowToObj(filterRow, tempFilterFieldsLength) {
		const dottedFilterRowObj = dot.dot(filterRow);
		const dottedFilterRowKey = Object.keys(dottedFilterRowObj)[0]
		var dottedFilterRowKeyArr = dottedFilterRowKey.split('.')

		const selectedOperator = dottedFilterRowKeyArr.pop()

		const selectedValue = Object.values(dottedFilterRowObj)[0]

		const selectedFilter = dottedFilterRowKeyArr.join('.')

		const index = tempFilterFieldsLength + 1

		return {
			index: index,
			filter: selectedFilter,
			operator: selectedOperator,
			value: selectedValue
		}
	},

	mapSmartListFieldsToFilterQuery(smartFilterFields) {
			//sort filter rows by key
			const filterQueries = {}
			smartFilterFields.forEach(row => {
					//convert dot notation to object
					
					const filterValue = { [row.selectedOperator] : row.selectedValue }
					var filterObject = {};
					var tempFilterObject = filterObject;
					row.selectedFilter.split('.').map((filterKey, index, selectedFilterArr) => {
							tempFilterObject = (tempFilterObject[filterKey] = (index == selectedFilterArr.length - 1 ? filterValue : {}))
					});
					//check if filterObject already exists in filterQueries and add or create it if not
					if (row.selectedFilter in filterQueries) {
							filterQueries[row.selectedFilter].push(filterObject)
					} else {
							filterQueries[row.selectedFilter] = [filterObject]
					}
			})

			var filters = { '$and' : [] }

			for (const key in filterQueries) {

					if (filterQueries[key].length > 1) {
							var filterRow = { '$or' : [] }

							if (AND_FILTER.indexOf(key) > -1) {
								filterRow = { '$and' : [] }
								filterQueries[key].forEach(filterObject => {
										filterRow['$and'].push(filterObject)
								})

							} else {
								filterQueries[key].forEach(filterObject => {
										filterRow['$or'].push(filterObject)
								})
							}

							filters['$and'].push(filterRow)

					} else {
							
							filters['$and'].push(filterQueries[key][0])
					}
			}

			const qsQuery = qs.stringify({
					filters: filters,
			}, {
					encodeValuesOnly: true,
			});

			return qsQuery
	},
                    
}