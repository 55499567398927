import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes.js'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = router.push;
// https://github.com/vuejs/vue-router/issues/2932 replace router push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (err) {
        
       // console.log(err)
      // If there really is an error, throw it
      // Should probably be more sophisticated based on the type of err
      //return Promise.reject(err);
    }
    // Otherwise resolve to false to indicate the original push call didn't go to its original
    // destination.
    // TODO: return the final route instead of false?
    return Promise.resolve(false);
  });
};
export default router