import store from '@/store'
import Constants from '@/utils/constants'
import DataService from '@/services/StrapiService';

/**
 * Creates a deep copy of an object so that no
 * "passed by reference side effects shit" happens
 * @param { Object } originalObject 
 * @returns 
 */
function deepCopyObject(originalObject) {
  return JSON.parse(JSON.stringify(originalObject))
}
/**
 * Fills an object with a deep value and creates every sub-object needed
 * @param {*} obj 
 * @param {*} keyPath ["attributes", "land", "data", "id"], 
 * @param {*} value 0 
 * -> returns {attributes: {land: {data: {id: 0}}
 */
function assignObjectFromPath(obj, keyPath, value) {
  var lastKeyIndex = keyPath.length - 1;
  for (var i = 0; i < lastKeyIndex; ++i) {
    var key = keyPath[i];
    if (!(key in obj)) {
      obj[key] = {}
    }
    obj = obj[key];
  }
  obj[keyPath[lastKeyIndex]] = value;
}

function getVenueCurrency(venue) {
  return (venue.attributes.currency.data != null) ? venue.attributes.currency.data.attributes.symbol : '€'
}

function getGrossPrice(netPrice) {
  const taxRate = store.getters.getVenue.attributes.taxRate.data.attributes.taxRate
  const grossPrice = Number(netPrice) + (Number(netPrice) * (taxRate / 100))
  return Number(grossPrice).toFixed(2)
}

function getNetPrice(grossPrice) {
  const taxRate = store.getters.getVenue.attributes.taxRate.data.attributes.taxRate
  let netPrice = Number(grossPrice) / (1 + (taxRate / 100))
  return Number(netPrice).toFixed(2)
}
function getWeq(purchasePrice, sellingPrice) {
  const ek = Number(purchasePrice)
  const vk = getNetPrice(Number(sellingPrice))
  var weq = 0
  if (ek > 0 && vk > 0) {
    weq = ek / vk * 100
  }
  return weq.toFixed(2)
}

function getWineSupplierText(generalText, supplierId, venueSupplierOptions) {
  var text = generalText
  if (supplierId) {
    var wineSupplier = venueSupplierOptions.find(supplier => supplier.value == supplierId)
    text = wineSupplier.text
  }
  return text
}

function getNotDeletedPurchasePrices(purchasePrices) {
  var filteredPrices = []
  if (purchasePrices) {
    filteredPrices = purchasePrices.filter(price => price.wineSupplier.data && !shouldDeletePurchasePrice(price));
  }
  return filteredPrices;
}

function getNotDeletedRatings(ratings) {
  var filteredRatings = []
  if (ratings) {
    filteredRatings = ratings.filter(rating => rating.ratingAgency.data && !shouldDeleteRating(rating));
  }
  return filteredRatings;
}

/**
 * Check if purchasePrice should be deleted
 */
function shouldDeletePurchasePrice(purchasePrice) {
  if (Constants.SHOULD_DELETE in purchasePrice) {
    return true;
  }
  else {
    return false;
  }
}

function shouldDeleteRating(rating) {
  if (Constants.SHOULD_DELETE in rating) {
    return true;
  }
  else {
    return false;
  }
}

function equalsIgnoreCase(a, b) {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
    : a === b;
}

function loadVenueSupplierOptions(venue) {
  var venueSupplierOptions = []
  venue.attributes.wineSuppliers.data.forEach((supplier) => {
    var option = {
      value: supplier.id,
      text: supplier.attributes.title
    }
    venueSupplierOptions.push(option)
  })
  return venueSupplierOptions
}

/**
 * Find the default venue supplier option, which is the one containing the word "Sammellieferant"
 * @param {*} venue 
 * @returns 
 */
function getDefaultVenueSupplierOption(venue) {
  var defaultOption = null;
  const venueSupplierOptions = loadVenueSupplierOptions(venue);
  defaultOption = venueSupplierOptions.find(option => option.text.toLowerCase().includes("sammellieferant"));
  return defaultOption
}

/**
 * used for ek parsing during import
 * transforms "1.000,00 €" and "1000€" and "1.000" to 1000
 *  "1.456,78" or "1456.78" or "1.456,78 €"  to 1456.78
 */
function parsePotentiallyGroupedFloat(stringValue) {
  // if it's already a number, return it
  if (!isNaN(stringValue)) {
    return parseFloat(stringValue)
  }
  // remove currency symbol:
  stringValue = stringValue.replace("€", "");
  stringValue = stringValue.trim();
  //  when comma,cut string with 2 digits after dot
  let parts = stringValue.split(',');
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, 2);
    stringValue = parts.join(',');
  }
  // remove all non-numeric characters:
  var result = stringValue.replace(/[^0-9]/g, '');

  if (/[,.]\d{1}$/.test(stringValue)) {
    // then add a dot as decimal separator
    result = result.replace(/(\d{1})$/, '.$1');
  }
  // check if we have a comma or dot as decimal separator
  if (/[,.]\d{2}$/.test(stringValue)) {
    // then add a dot as decimal separator
    result = result.replace(/(\d{2})$/, '.$1');
  }
  return parseFloat(result);
}

/**
 * used for parsing long supplier or grapeVariety strings in Create New Wine Modal
 */
function limitString(length, str) {
  if (str.length <= length) {
    return str;
  }
  return str.slice(0, length) + '…';
}

function checkForNullEmptyOrNegative(value) {
  if (value === "" || value === null || value < 0) {
    return 0;
  } else {
    return value;
  }
}
function filterInventoryVenueWines(inventoryVenueWines) {
  const checkedWines = inventoryVenueWines.filter((wine) => wine.inventoryVenueWineState === Constants.inventory.CHECKED || wine.inventoryVenueWineState === Constants.inventory.TRANSFERRED);
  const uncheckedWines = inventoryVenueWines.filter((wine) => wine.inventoryVenueWineState !== Constants.inventory.CHECKED || wine.inventoryVenueWineState === Constants.inventory.TRANSFERRED_UNCHECKED);
  const incompleteWines = checkedWines.filter((wine) => (wine.actualAmountFloat === null) || wine.purchasePrice === null || wine.purchasePrice === 0);
  const completeWines = checkedWines.filter((wine) => wine.actualAmountFloat !== null && wine.shelf !== null && wine.shelf !== '' && wine.purchasePrice !== null && wine.purchasePrice !== 0);
  const divergentStock = checkedWines.filter((wine) => wine.actualAmountFloat !== wine.targetAmountFloat)
  const duplicateWines = inventoryVenueWines.filter((wine) => wine.duplicate == true)

  return {
    checkedWines: checkedWines,
    uncheckedWines: uncheckedWines,
    incompleteWines: incompleteWines,
    completeWines: completeWines,
    divergentStock: divergentStock,
    allWines: inventoryVenueWines,
    duplicateWines: duplicateWines
  }
}
function processVenueWineIds(venueWineIds, inventoryId, loadInventory, $route, $store, $t) {
  venueWineIds.forEach(venueWineId => {
    DataService.getVenueWineDetail(venueWineId)
      .then(response => {
        const venueWine = response.data.data;
        const inventoryVenueWine = {
          venue_wine: venueWine.id,
          actualAmountFloat: venueWine.attributes.amountFloat,
          targetAmountFloat: 0,
          sellingPrice: venueWine.attributes.sellingPrice,
          shelf: venueWine.attributes.shelfNumber,
          inventoryVenueWineState: Constants.inventory.CHECKED,
          inventory: inventoryId
        }
        DataService.createInventoryVenueWine(inventoryVenueWine)
          .then(() => {
            loadInventory($route.params.id, $route.params.filterString);
            const alertobject = {
              text: $t('inventory.addedWines'),
              variant: 'success'
            }
            $store.commit('setAlertText', alertobject)
          })
          .catch(e => {
            console.log(e);
            const alertobject = {
              text: $t('inventory.errorWines'),
              variant: 'danger'
            }
            $store.commit('setAlertText', alertobject)
          });
      })
      .catch(e => {
        console.log(e);
      });
  });
}
function isInventoryOnly(role) {
  return role?.name == 'InventoryOnly'
}
function getKeysByValue(object, value) { return Object.keys(object).filter(key => object[key] === value); }

function reduceText(text, count, insertDots){
  return text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "");
}
function isInt(n){
  return Number(n) === n && n % 1 === 0;
}
function isFloat(n){
  return Number(n) === n && n % 1 !== 0;
}

export {
  parsePotentiallyGroupedFloat,
  deepCopyObject,
  assignObjectFromPath,
  getVenueCurrency,
  getNetPrice,
  getGrossPrice,
  getWeq,
  getWineSupplierText,
  getNotDeletedPurchasePrices,
  getNotDeletedRatings,
  shouldDeletePurchasePrice,
  shouldDeleteRating,
  equalsIgnoreCase,
  loadVenueSupplierOptions,
  getDefaultVenueSupplierOption,
  limitString,
  checkForNullEmptyOrNegative,
  filterInventoryVenueWines,
  getKeysByValue,
  isInventoryOnly,
  processVenueWineIds,
  reduceText,
  isInt,
	isFloat,
}