const state = {
    filter: window.localStorage.getItem('filter') || '{}',
    listtitle: window.localStorage.getItem('listtitle') || 'MEINE WEINE',
    bottleSizes:  window.localStorage.getItem('bottleSizes') || '[]',
    ratingAgencies:  window.localStorage.getItem('ratingAgencies') || '[]',
    currencies:  window.localStorage.getItem('currencies') || '[]',
    taxRates:  window.localStorage.getItem('taxRates') || '[]',
    locales:  window.localStorage.getItem('locales') || '[]',
}
const getters = {
    // wird in nav auf empty gesetzt, wenn route nicht wines ist
    getFilter: state => {
        return JSON.parse(state.filter)
    },
    getBottleSizes: state => {
        return JSON.parse(state.bottleSizes)
    },
    getRatingAgencies: state => {
      return JSON.parse(state.ratingAgencies)
    },
    getCurrencies: state => {
      return JSON.parse(state.currencies)
    },
    getTaxRates: state => {
        return JSON.parse(state.taxRates)
      },

    getCurrentListTitle: state => {
        return state.listtitle
    },

    getLocales: state => {
      return JSON.parse(state.locales)
    },
}
const actions = {
}
const mutations = {
    updateFilter: (state, filter) => {
        localStorage.setItem('filter', JSON.stringify(filter))
        state.filter = JSON.stringify(filter)
    },
    updateCurrentListTitle: (state, title) => {
        localStorage.setItem('listtitle', title)
        state.listtitle = title
    },

    setBottleSizes: (state, bottleSizes) => {
        localStorage.setItem('bottleSizes', JSON.stringify(bottleSizes))
        state.bottleSizes = JSON.stringify(bottleSizes)
    },
    setRatingAgencies: (state, ratingAgencies) => {
      localStorage.setItem('ratingAgencies', JSON.stringify(ratingAgencies))
      state.ratingAgencies = JSON.stringify(ratingAgencies)
  },
    setCurrencies: (state, currencies) => {
      localStorage.setItem('currencies', JSON.stringify(currencies))
      state.currencies = JSON.stringify(currencies)
    },
    setTaxRates: (state, taxRates) => {
        localStorage.setItem('taxRates', JSON.stringify(taxRates))
        state.taxRates = JSON.stringify(taxRates)
      },
    
    setLocales: (state, locales) => {
      localStorage.setItem('locales', JSON.stringify(locales))
      state.locales = JSON.stringify(locales)
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}