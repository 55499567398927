<template>
    <b-modal hide-footer size="lg" id="modal-activities" :title="$t('activities.title')">
        <b-row class="filterRow">
            <b-col class="getAllContent">
                <b-button-group class="toggleButtons wineViewFilter">
                  <b-button :class="filter=='sold'? 'active':''" @click="filter = 'sold'">{{$t('activities.soldWines')}}</b-button>
                  <b-button :class="filter=='open'? 'active':''" @click="filter = 'open'">{{$t('activities.soldOpenWines')}}</b-button>
                  <b-button :class="filter=='added'? 'active':''" @click="filter = 'added'">{{$t('activities.addedWines')}}</b-button>
                  <b-button :class="filter=='moved'? 'active':''" @click="filter = 'moved'">{{$t('activities.movedWines')}}</b-button>
                  <b-button :class="filter=='removed'? 'active':''" @click="filter = 'removed'">{{$t('activities.removedWines')}}</b-button>
                </b-button-group>  
            </b-col>
        </b-row>
        <div class="spacer l"></div>
        <b-row>
          <b-row class="activitiesHeader">
            <b-col></b-col>
            <b-col>{{ headerWording('first') }}</b-col>
            <b-col v-if="filter == 'moved'">{{ headerWording('second') }}</b-col>
            <b-col>{{$t('activities.details')}}</b-col>
          </b-row>
        </b-row>  
        <div v-if="!loading" class="innerContainer">
          <b-row v-for="(day, index) in activities[filter]" :key="index">
            <b-row>
              <b-col> 
                <h2>{{ getFormatedDate(index) }}</h2>
              </b-col>  
            </b-row>
            <b-row v-for="(row, indexday) in day" :key="indexday" class="dataRow">
              <b-col>
                <span>{{ row["winery"] }}</span><br />
                {{ row["wineTitle"] }} <span v-if="row['wineYear'] > 0">{{ row["wineYear"] }}</span>
              </b-col>
              <b-col v-if="filter === 'added'"><span>{{ row["diff"] }}</span></b-col>
              <b-col v-if="filter !== 'added'"><span>{{ -row["diff"] }}</span></b-col>
              <b-col v-if="filter === 'moved'"><span>{{ row["newVenueTitle"] }}</span></b-col>
              <b-col><span v-if="row['user']">{{ row["user"] }} - </span>{{ row["time"] }}</b-col>
            </b-row>
          </b-row>
        </div>
        <div v-else>
          <LoadingAnimation />
        </div>
    </b-modal>
</template>

<script>
  import DataService from '@/services/StrapiService'
  import { mapGetters } from 'vuex'
  import LoadingAnimation from '@/components/LoadingAnimation.vue'
  import DateTimeService from '@/services/DateTimeService'

  export default {
      name: 'ActivitiesModal',
      data: function () {
          return {
            activities: {},
            filter: 'sold',
            from: this.getLast30Days(),
            to: this.getEndOfToday(),
            loading: true
          }
      },
      created () {
      },
      methods: {
        loadActivities () {
          this.loading = true
          DataService.getActivitiesOverTime(this.from, this.to).then((response) => {
            this.activities = response['data'].data
            this.loading = false
          }).catch((err) => {
            this.loading = false
            console.log(err)
          })
        },
        headerWording(column) {
          var text = ""
          if ( column === 'first') {
            text = "Hinzugefügte Weine"
            if ( this.filter === 'moved' ) {
              text = this.$t('activities.addedWines')
            }
            if ( this.filter === 'sold' ) {
              text = this.$t('activities.soldWines')
            }
            if ( this.filter === 'open' ) {
              text = this.$t('activities.soldOpenWines')
            }
            if ( this.filter === 'removed' ) {
              text = this.$t('activities.removedWines')
            }
          }
          if ( column === 'second') {
            if ( this.filter === 'moved' ) {
              text = this.$t('activities.wineMovedTo')
            }
          }
          return text
        },
        getFormatedDate(dateString) {
          var newDate = new Date(dateString)
          return DateTimeService.dateToFormattedstring(newDate, false, this.$i18n.locale)
        },
        getEndOfToday() {
          const date = new Date();
          const dateString = DateTimeService.dateToString(date, true)
          return dateString
        },
        getLast30Days() {
          const date = new Date();
          var lastWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29);
          const dateString = DateTimeService.dateToString(lastWeek, false)
          return dateString
        },
      },
      components: {
        LoadingAnimation
      },
      computed: {
          ...mapGetters(['getVenue','getBottleSizes'])
      },
      mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
          if (modalId === 'modal-activities')
          {
            this.loadActivities()
          }
        })
      }
  }
</script>
