<template>
  <div class="color-100 w-400 flex-row align-center justify-start gap-xs">
      <BackButton />
      <div v-for="(textObject, index) in getTopBarHeadLine.texts" :key="textObject.text" class="flex-row align-center font-16" style="height: 100%;" :style="textObject.link ? 'cursor:pointer' :''">
        <span @click="goTo(index)" style="margin: 0;" class="breadcrumb" :class="index == 0 ? 'color-150' : ''" >
          {{ textObject.text}}
        </span>
        <div v-if="index < getTopBarHeadLine.texts.length - 1" class="pl-xs"  :class="index == 0 ? 'color-150' : ''" > / </div>
      </div>

      <InventoryState class="ml-xs" :inventoryState="getTopBarHeadLine.state" />
    </div>
</template>
<script>
import BackButton from '@/components/buttons/BackButton.vue';
import InventoryState from '@/components/inventory/InventoryState.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    BackButton,
    InventoryState
  },
  computed: {
    ...mapGetters( ['getTopBarHeadLine'])
  },
  methods: {
    goTo(index) {
      const link = this.getTopBarHeadLine.texts.length - 1 - index
      if (link > 0) {
        this.$router.go(-link)
      }
    }
  }
}
</script>
<style scoped>
.breadcrumb {
  text-transform: uppercase;
}
</style>