import Vue from "vue";
import Vuex from "vuex";
import Results from "./results.js";
import User from "./user.js";
import Events from "./events.js";
import Wine from "./wine.js";
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        Results,
        User,
        Events,
        Wine
    }
});