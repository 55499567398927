<template>
    <b-modal size="md"  id="playlistcreate"  v-model="showPlaySmartListPopup" :title="title">
        <b-row>
            <b-col cols="6">              
                <h3>{{ $t('winelist.editModal.listName')}}</h3>
            </b-col>
        </b-row>
        <b-row style="justify-content: space-between">
            <b-col cols="6">              
                <b-form-input ref="newList" v-model="listName" v-on:keyup.enter="savePlaylist" placeholder=""></b-form-input>
            </b-col>
            <b-col class="getAllContent" v-if="playlistId == null && smartListId == null">
                <b-button-group v-if="(playlistId == null && smartListId == null) || isFavoriteSmartList == false" class="toggleButtons selectListType">
                    <b-button @click="listTypeSmartActive=false" :class="listTypeSmartActive ? '':'active'">{{ $t('winelist.editModal.typeList')}}</b-button>
                    <b-button @click="listTypeSmartActive=true" :class="listTypeSmartActive ? 'active':''">{{ $t('winelist.editModal.typeSmartlist')}}</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <div class="spacer m"></div>
        <div v-if="listTypeSmartActive">
            <b-row v-for="(smartFilterField, index) in smartFilterFields" :key="smartFilterField.index">
                <div class="spacer s"></div>
                <b-col cols="3">
                    <b-dropdown 
                        :text="getFilterText(index, smartFilterField.selectedFilter)" 
                    >
                        <b-dropdown-item v-for="option in smartFilterField.filterOptions" :key="option.value" :value="option.value" @click="smartFilterField.selectedFilter = option.value; refreshSmartFilterFields()">
                        {{option.text}}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>

                <b-col cols="3">
                    <b-dropdown 
                        :text="getFilterText(index, smartFilterField.selectedOperator, 'operator')" 
                    >
                        <b-dropdown-item v-for="option in smartFilterField.operatorOptions" :key="option.value" :value="option.value" @click="smartFilterField.selectedOperator = option.value">
                            {{translateOperator(option.value)}}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col cols="4">
                    <b-form-input 
                        v-if="smartFilterField.inputType=='input'" 
                        v-model="smartFilterField.selectedValue"
                    >
                    </b-form-input>
                    <div style="position: relative" v-if="smartFilterField.inputType=='search'">
                        <SearchDataList :isLastSearch="false" :searchFromOutside="setAutoSuggestSearchItem(smartFilterField).text" :autoSuggestComplete="getAutoSuggestionsObjectFromOptions(smartFilterField.valueOptions)" :itemClickedFunction="setValueFromAutoSuggestField.bind(this, smartFilterField)" :enterPressedFunction="setValueFromAutoSuggestField.bind(this, smartFilterField)" />
                    </div>
                    <b-dropdown
                        v-if="smartFilterField.inputType=='select' && getFilterText(index, smartFilterField.selectedFilter) !== $t('general.region')" 
                        :text="getFilterText(index, smartFilterField.selectedValue, 'valueOptions')"
                    >
                        <b-dropdown-item v-for="option in smartFilterField.valueOptions" :key="option.value" :value="option.value" @click="smartFilterField.selectedValue = option.value">
                        {{option.text}}
                        </b-dropdown-item>
                    </b-dropdown>
                    <div style="position: relative" v-if="smartFilterField.inputType=='select' && getFilterText(index, smartFilterField.selectedFilter) === $t('general.region')" >
                        <SearchDataList :isLastSearch="false" :searchFromOutside="getFilterText(index, smartFilterField.selectedValue, 'valueOptions')" :autoSuggestComplete="getAutoSuggestionsObjectFromOptions(smartFilterField.valueOptions)" :itemClickedFunction="setValueFromAutoSuggestField.bind(this, smartFilterField)" :enterPressedFunction="setValueFromAutoSuggestField.bind(this, smartFilterField)" />
                    </div>
                </b-col>
                
                <b-col cols="2" style="justify-content: flex-end; display: flex;">
                    <b-button class="iconButton add" @click="addSmartFilterFieldRow()"></b-button>
                    <b-button class="iconButton reduce" @click="removeSmartFilterFieldRow(smartFilterField.index)"></b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-center">
                    <div class="spacer m"></div>
                        <LoadingAnimation v-if="loading"/>
                </b-col>
            </b-row>
        </div>
        <template class="poolinqModalFooter" #modal-footer="{ cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" variant="danger" @click="cancel()">
                {{ $t('general.buttons.cancel')}}
            </b-button>
            <b-button :disabled="listName == ''" size="sm" variant="success" @click="save()">
                {{ $t('general.buttons.save')}}
            </b-button>
        </template>
    </b-modal>
</template>



<script>
    const qs = require('qs');
		import * as Utils from '@/utils/functions'
    import SearchDataList from '@/components/SearchDataList.vue'
    import DataService from '@/services/StrapiService'
		import PlaySmartListMapping from '@/services/mapping/PlaySmartListMapping'
    import { mapGetters } from 'vuex'
    import LoadingAnimation from '@/components/LoadingAnimation.vue'

    const SMART_LIST_PRICE_OPERATOR_OPTIONS = [
        { text: 'größer als', value: '$gt'},
        { text: 'kleiner als', value: '$lt'},
    ]

    const SMART_LIST_RELATION_OPERATOR_OPTIONS = [
        { text: 'ist', value: '$eq'},
        { text: 'ist nicht', value: '$ne'},
    ]

    const SMART_LIST_NUMERIC_OPERATOR_OPTIONS = SMART_LIST_RELATION_OPERATOR_OPTIONS.concat(SMART_LIST_PRICE_OPERATOR_OPTIONS)

    const SMART_LIST_FILTER_KEY_WINERY = 'wine.winery.id'
    const SMART_LIST_FILTER_KEY_TITLE = 'title'
    const SMART_LIST_FILTER_KEY_GVA = 'wine.grapeVariety.id'
    const SMART_LIST_FILTER_KEY_LAND = 'wine.land.id'
    const SMART_LIST_FILTER_KEY_REGION = 'wine.region.id'
    const SMART_LIST_FILTER_KEY_EK = 'purchasePriceAvg'
    const SMART_LIST_FILTER_KEY_VK = 'sellingPrice'
    const SMART_LIST_FILTER_KEY_YEAR = 'year'
    const SMART_LIST_FILTER_KEY_TYPE = 'wine.wineType.id'
    const SMART_LIST_FILTER_KEY_BOTTLESIZE = 'bottleSize.title'
    const SMART_LIST_FILTER_KEY_SUPPLIER = 'purchasePrices.wineSupplier.id'

    export default {
        name: 'NewPlaySmartListModal',
        data: function () {
            return {
                loading: false,
                showPlaySmartListPopup: false,
                playlistId: null,
                smartListId: null,
                isFavoriteSmartList: false,
                listName: '',
                title: '',
                listTypeSmartActive: false,
                smartFilterFields: [],
                wineTypeOptions: [],
                regionOptions: [],
                landOptions: [],
                wineryOptions: [],
                grapeVarietyOptions: [],
                wineSupplierOptions: [],
                bottleSizeOptions: [],
                wineTitleOptions: [],
                search: '',
                arrowCounter: -1,
                searchInputHasFocus: false,
                smartListFilterOptions: [
                    { text: this.$t('general.winery'), value: SMART_LIST_FILTER_KEY_WINERY },
                    { text: this.$t('general.winetitle'), value: SMART_LIST_FILTER_KEY_TITLE },
                    { text: this.$t('general.grapeVariety'), value: SMART_LIST_FILTER_KEY_GVA },
                    { text: this.$t('general.country'), value: SMART_LIST_FILTER_KEY_LAND },
                    { text: this.$t('general.region'), value: SMART_LIST_FILTER_KEY_REGION },
                    { text: this.$t('general.ek'), value: SMART_LIST_FILTER_KEY_EK },
                    { text: this.$t('general.vk'), value: SMART_LIST_FILTER_KEY_VK },
                    { text: this.$t('general.vintage'), value: SMART_LIST_FILTER_KEY_YEAR },
                    { text: this.$t('general.color'), value: SMART_LIST_FILTER_KEY_TYPE },
                    { text: this.$t('general.bottleSize'), value: SMART_LIST_FILTER_KEY_BOTTLESIZE },
                    { text: this.$t('general.supplier'), value: SMART_LIST_FILTER_KEY_SUPPLIER }
                ]
            }
        },
        created () {
        },
        methods: {
            translateOperator(oCase) {
                if(oCase==="$gt") {
                    return this.$t('winelist.editModal.conditionGreaterThan')
                }
                if(oCase==="$lt") {
                    return this.$t('winelist.editModal.conditionLessThan')
                }
                if(oCase==="$eq") {
                    return this.$t('winelist.editModal.conditionEqual')
                }
                if(oCase==="$ne") {
                    return this.$t('winelist.editModal.conditionNotEqual')
                }
            },
            getFilterText(index, filterValue, filterCase) {
                var filterText = this.$t('general.dropdownText')
                var optionObject = this.smartFilterFields[index].filterOptions
                if ( filterCase === "operator" ) {
                    optionObject = this.smartFilterFields[index].operatorOptions
                }
                if ( filterCase === "valueOptions" ) {
                    optionObject = this.smartFilterFields[index].valueOptions
                }
                for (var prop in optionObject) {
                    if (optionObject[prop].value && filterValue) {
                        if (optionObject[prop].value.toString() === filterValue.toString()) {
                            filterText = optionObject[prop].text.toString()
                            if ( filterCase === "operator" ) {
                                filterText =  this.translateOperator(optionObject[prop].value)
                            }
                        }
                    }
                }
                return filterText
            },
            showModal(id, smartActive) {
                this.showPlaySmartListPopup = true
                this.listTypeSmartActive = smartActive

                if (smartActive) {
                    this.smartListId = id
                } else {
                    this.playlistId = id
                }
                this.listName = id != null ?  this.getCurrentListTitle : ''
                this.title = id != null ? this.$t('winelist.editModal.editList') : this.$t('winelist.editModal.newList')
                if(!id ) {
                    setTimeout(x => {
                        console.log(x)
                        this.$nextTick(() => this.setFocus()); // this works great, just watch out with going to fast !!!
                    }, 100);
                }
                this.loadSmartFilterFields()
            },
            setFocus: function() {
                // Note, you need to add a ref="search" attribute to your input.
                this.$refs.newList.focus();
            },
            resetModal() {
                this.loading = false
                this.playlistId = null
                this.smartListId = null
                this.isFavoriteSmartList = false
                this.listName = ''
                this.title = ''
                this.listTypeSmartActive = false
                this.smartFilterFields = []
                this.wineTypeOptions = []
                this.regionOptions = []
                this.landOptions = []
                this.wineryOptions = []
                this.grapeVarietyOptions = []
                this.wineSupplierOptions = []
                this.bottleSizeOptions = []
                this.wineTitleOptions = []
            },
            async loadSmartFilterFields() {
                this.loading = true
                if (this.smartListId != null) {
                    await this.loadSmartFieldsForEditing()
                } else if (this.smartFilterFields.length === 0) {
                    await this.addSmartFilterFieldRow()
                }
                this.loading = false
            },
            async loadOptionsForFilter(selectedFilter) {
                const venueId = this.getVenue.id
                switch(selectedFilter) {
                    case SMART_LIST_FILTER_KEY_WINERY:
                        if (this.wineryOptions.length == 0) {
                            this.loading = true
                            await DataService.getWineriesForVenue(venueId, 'title').then((response) => {
                                this.wineryOptions = response['data'].data.map(winery => {
                                    return {text: winery.title , value: winery.id }
                                })
                            }).catch((err) => {
                                console.log(err)
                            })
                            this.loading = false
                        }

                        break
                    case SMART_LIST_FILTER_KEY_TITLE:
                        if (this.wineTitleOptions.length == 0) {
                            this.loading = true
                            await DataService.getVenueWinesSorted('title').then((response) => {    
                                const wineTitles = response['data'].data.map(venueWine => {
                                    return {text: venueWine.attributes.title , value: venueWine.attributes.title }
                                })
                                //prevent duplicate wineTitles
                                this.wineTitleOptions = wineTitles.filter((val, index, self) =>
                                    index === self.findIndex((element) => (
                                        element.text === val.text && element.value === val.value
                                    ))
                                )
                            }).catch((err) => {
                                console.log(err)
                            })
                            this.loading = false
                        }
                        break
                    case SMART_LIST_FILTER_KEY_GVA:
                        if (this.grapeVarietyOptions.length == 0) {
                            this.loading = true
                            await DataService.getGrapeVarietiesForVenue(venueId, 'title').then((response) => {
                                this.grapeVarietyOptions = response['data'].data.map(grapeVariety => {
                                    return {text: grapeVariety.title , value: grapeVariety.id }
                                })
                            }).catch((err) => {
                                console.log(err)
                            })
                            this.loading = false
                        }
                        break
                    case SMART_LIST_FILTER_KEY_LAND:
                        if (this.landOptions.length == 0) {
                            this.loading = true
                            await DataService.getLandsForVenue(venueId, 'title').then((response) => {
                                this.landOptions = response['data'].data.map(land => {
                                    return { text: this.$t('countries.'+land.localeKey), value: land.id }
                                })
                            }).catch((err) => {
                                console.log(err)
                            })
                            this.loading = false
                        }
                        break
                    case SMART_LIST_FILTER_KEY_REGION:
                        if (this.regionOptions.length == 0) {
                            this.loading = true
                            await DataService.getRegionsForVenue(venueId, 'title').then((response) => {
                                this.regionOptions = response['data'].data.map(region => {
                                    const regionTitle = region.localeKey ? this.$t('regions.' + region.localeKey) : region.title
                                    return { text: regionTitle, value: region.id }
                                })
                            }).catch((err) => {
                                console.log(err)
                            })
                            this.loading = false
                        }
                        break
                    case SMART_LIST_FILTER_KEY_BOTTLESIZE:
                        if (this.bottleSizeOptions.length == 0) {
                            this.loading = true
                            await DataService.getBottleSizesForVenue(venueId, 'amount').then((response) => {
                                this.bottleSizeOptions = response['data'].data.map(bottleSize => {
                                    return {text: bottleSize.title , value: bottleSize.title }
                                })
                            }).catch((err) => {
                                console.log(err)
                            })
                            this.loading = false
                        }
                        break
                    case SMART_LIST_FILTER_KEY_SUPPLIER:
                        if (this.wineSupplierOptions.length == 0) {
                            this.loading = true
                            await DataService.getAllWineSuppliers(venueId, 'title').then((response) => {
                                this.wineSupplierOptions = response['data'].data.map(wineSupplier => {
                                    return {text: wineSupplier.attributes.title , value: wineSupplier.id }
                                })
                            }).catch((err) => {
                                console.log(err)
                            })
                            this.loading = false
                        }
                        break
                    case SMART_LIST_FILTER_KEY_TYPE:
                        if (this.wineTypeOptions.length == 0) {
                            this.loading = true
                            await DataService.getWineTypesForVenue(venueId, 'title').then((response) => {
                                this.wineTypeOptions = response['data'].data.map(wineType => {
                                    return {text: this.$t('wineTypes.'+wineType.localeKey) , value: wineType.id }
                                })
                            }).catch((err) => {
                                console.log(err)
                            })
                            this.loading = false
                        }
                        break
                }
            },
            async loadSmartFieldsForEditing() {
                this.loading = true

                await DataService.getSmartList(this.smartListId).then(async (response) => {
                    const tempFilterFields = []
										console.log(response['data'].data.attributes.filterQuery)
                    const filterQuery = qs.parse(response['data'].data.attributes.filterQuery, { depth: 15 })
										console.log(filterQuery)

                    this.isFavoriteSmartList = response['data'].data.attributes.type === 'favorite'

                    if (Array.isArray(filterQuery.filters.$and)) {

                        for (const filterRow of filterQuery.filters.$and) {

                            const queryFilterKey = Object.keys(filterRow)[0]

                            if (queryFilterKey == '$or' || queryFilterKey == '$and') {

                                for (const filterAndOrRow of filterRow[queryFilterKey]) {
                                    const filterRowObj = PlaySmartListMapping.mapFilterAndOrRowToObj(filterAndOrRow, tempFilterFields.length)				
                                    const row = await this.makeFilterRow(filterRowObj.index, filterRowObj.filter, filterRowObj.operator, filterRowObj.value)
                                    tempFilterFields.push(row)
                                }

                            } else {

															const filterRowObj = PlaySmartListMapping.mapFilterRowToObj(filterRow, tempFilterFields.length)
                              const row = await this.makeFilterRow(filterRowObj.index, filterRowObj.filter, filterRowObj.operator, filterRowObj.value)
                              tempFilterFields.push(row)
                            }
                        }
                    }

                    this.smartFilterFields = Utils.deepCopyObject(tempFilterFields)

                    this.loading = false

                }).catch((err) => {
                    console.log(err)
                    this.loading = false
                })    
            },
            async refreshSmartFilterFields() {
                const tempFilterFields =  JSON.parse(JSON.stringify(this.smartFilterFields))

                for (const [index, row] of this.smartFilterFields.entries()) {
                    tempFilterFields[index] = await this.makeFilterRow(index, row.selectedFilter, row.selectedOperator, row.selectedValue)
                }

                this.smartFilterFields = Utils.deepCopyObject(tempFilterFields)
            },
            async makeFilterRow(index, selectedFilter, selectedOperator, selectedValue) {

                const tempFilterFieldRow = {
                    index: index,
                    inputType: 'select', 
                    filterOptions: this.smartListFilterOptions, 
                    valueOptions: null, 
                    operatorOptions: null, 
                    selectedFilter: selectedFilter, 
                    selectedOperator: selectedOperator, 
                    selectedValue: selectedValue 
                }
                
                //lazy load options for filter row
                await this.loadOptionsForFilter(selectedFilter)

                switch(selectedFilter) {
                    case SMART_LIST_FILTER_KEY_WINERY:
                        tempFilterFieldRow.inputType = 'search'
                        tempFilterFieldRow.valueOptions = this.wineryOptions
                        tempFilterFieldRow.operatorOptions = SMART_LIST_RELATION_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_TITLE:
                        tempFilterFieldRow.inputType = 'search'
                        tempFilterFieldRow.valueOptions = this.wineTitleOptions
                        tempFilterFieldRow.operatorOptions = SMART_LIST_RELATION_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_GVA:
                        tempFilterFieldRow.inputType = 'search'
                        tempFilterFieldRow.valueOptions = this.grapeVarietyOptions
                        tempFilterFieldRow.operatorOptions = SMART_LIST_RELATION_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_LAND:
                        tempFilterFieldRow.inputType = 'select'
                        tempFilterFieldRow.valueOptions = this.landOptions
                        tempFilterFieldRow.operatorOptions = SMART_LIST_RELATION_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_REGION:
                        tempFilterFieldRow.inputType = 'select'
                        tempFilterFieldRow.valueOptions = this.regionOptions
                        tempFilterFieldRow.operatorOptions = SMART_LIST_RELATION_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_VK:
                        tempFilterFieldRow.inputType = 'input'
                        tempFilterFieldRow.valueOptions = null
                        tempFilterFieldRow.operatorOptions = SMART_LIST_PRICE_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_EK:
                        tempFilterFieldRow.inputType = 'input'
                        tempFilterFieldRow.valueOptions = null
                        tempFilterFieldRow.operatorOptions = SMART_LIST_PRICE_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_YEAR:
                        tempFilterFieldRow.inputType = 'input'
                        tempFilterFieldRow.valueOptions = null
                        tempFilterFieldRow.operatorOptions = SMART_LIST_NUMERIC_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_BOTTLESIZE:
                        tempFilterFieldRow.inputType = 'select'
                        tempFilterFieldRow.valueOptions = this.bottleSizeOptions
                        tempFilterFieldRow.operatorOptions = SMART_LIST_RELATION_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_SUPPLIER:
                        tempFilterFieldRow.inputType = 'select'
                        tempFilterFieldRow.valueOptions = this.wineSupplierOptions
                        tempFilterFieldRow.operatorOptions = SMART_LIST_RELATION_OPERATOR_OPTIONS
                        break
                    case SMART_LIST_FILTER_KEY_TYPE:
                        tempFilterFieldRow.inputType = 'select'
                        tempFilterFieldRow.valueOptions = this.wineTypeOptions
                        tempFilterFieldRow.operatorOptions = SMART_LIST_RELATION_OPERATOR_OPTIONS
                        break
                }

                return tempFilterFieldRow
            },
            async addSmartFilterFieldRow() {
                const index = this.smartFilterFields.length + 1
                this.smartFilterFields.push({
                    index: index, 
                    inputType: 'select', 
                    filterOptions: this.smartListFilterOptions, 
                    valueOptions: null, 
                    operatorOptions: SMART_LIST_RELATION_OPERATOR_OPTIONS, 
                    selectedFilter: null, 
                    selectedOperator: null, 
                    selectedValue: null 
                })
                await this.refreshSmartFilterFields()
            },
            async removeSmartFilterFieldRow(index) {
                if (this.smartFilterFields.length > 1) {
                    this.smartFilterFields.splice(index, 1)
                    await this.refreshSmartFilterFields()
                }
            },
            save() {
                if (this.listTypeSmartActive) {
                    this.saveSmartList()
                } else {
                    this.savePlaylist()
                }
            },
            savePlaylist () {
                var venue = this.getVenue
                var playlist = {
                    attributes : {
                        title: this.listName,
                        venue: venue
                    } 
                }
                // rename
                if (this.playlistId != null && this.playlistId > 0) {
                    DataService.updatePlaylist(this.playlistId, playlist).then(() => {
                        this.$store.commit('updateCurrentListTitle', this.listName)
                        this.$store.commit('reloadVenue')
                        this.showPlaySmartListPopup = false
                    }).catch((err) => {
                        console.log(err)
                    })
                    
                } else {
                // create new
                    DataService.createPlaylist(playlist).then(() => {
                        this.showPlaySmartListPopup = false
                        this.$store.commit('reloadVenue')
                        this.resetModal()
                    }).catch((err) => {
                        console.log(err)
                    })
                }
               
            },
            saveSmartList () {
                this.loading = true
                var valid = true

                if (this.listName === "") {
                    valid = false
                }
                
                this.smartFilterFields.forEach(row => {
                    if (!(row.selectedFilter && 
                        row.selectedOperator &&
                        row.selectedValue)) {
                            valid = false
                    }
                })

                if (this.isFavoriteSmartList) {
                    valid = false 
                }

                if (!valid) {
                    var alertobject = {
                        text: this.$t('winelist.editModal.errorMessage'),
                        variant: 'danger'
                    }
                    this.$store.commit('setAlertText', alertobject)
                    this.loading = false
                    return
                }
                
                const filterQuery = PlaySmartListMapping.mapSmartListFieldsToFilterQuery(this.smartFilterFields)
								console.log(filterQuery)
								
                const smartList = {
                    title: this.listName,
                    filterQuery: filterQuery,
                    venue: this.getVenue.id,
                    type: "normal",
                }

                if (this.smartListId != null) {
                    DataService.updateSmartList(this.smartListId, smartList).then((response) => {
                        const smartListFilter = response['data'].data
                        const filter = { smartList : this.smartListId }

                        this.$store.commit('updateCurrentListTitle', smartListFilter.attributes.title)
                        this.$store.commit('reloadVenue')
                        this.$store.commit('updateFilter', filter)
                        this.$store.commit('reloadWines')
                        
                        this.showPlaySmartListPopup = false
                        this.resetModal()

                    }).catch((err) => {
                        this.loading = false
                        console.log(err)
                    })
                } else {
                    DataService.createSmartList(smartList).then((response) => {

                        const smartListId = response['data'].data.id
                        this.showPlaySmartListPopup = false
                        var filter = { smartList : smartListId}
                        this.$store.commit('updateFilter', filter)
                        this.$router.push({ name: 'Wines', query: { smartListId: smartListId.toString() } })
                        this.$store.commit('reloadVenue')
                        this.resetModal()
                       
                    }).catch((err) => {
                        this.loading = false
                        console.log(err)
                    })
                }
            },
            
            getAutoSuggestionsObjectFromOptions( smartFilterOptions ) {
                if (smartFilterOptions) {
                    return smartFilterOptions.map( smartFilterOption => { return { text: smartFilterOption.text, value: smartFilterOption.value } } )
                }
                return null;
            },
            setAutoSuggestSearchItem ( smartFilterField ) {        
              var item = {
                value: '',
                text: '',
              }
              for (var key in smartFilterField.valueOptions) {

                if ( smartFilterField.selectedFilter === SMART_LIST_FILTER_KEY_WINERY || smartFilterField.selectedFilter === SMART_LIST_FILTER_KEY_GVA ) {
                    if (smartFilterField.valueOptions[key].value == smartFilterField.selectedValue) {
                        item.value = smartFilterField.valueOptions[key].value
                        item.text = smartFilterField.valueOptions[key].text
                    }
                } else {
                    console.log(smartFilterField.selectedValue)
                    if (smartFilterField.valueOptions[key].text == smartFilterField.selectedValue) {
                        item.text = smartFilterField.valueOptions[key].text
                    }
                }
              }

              return item
            },
            setValueFromAutoSuggestField ( smartFilterField, item ) {
              var value = ''
              for (var key in smartFilterField.valueOptions) {
                if (smartFilterField.valueOptions[key].text == item.text && smartFilterField.valueOptions[key].value == item.value) {
                  value = smartFilterField.valueOptions[key].value
                }
              }
              smartFilterField.selectedValue = value
            }
        },
        components: { LoadingAnimation, SearchDataList},
        computed: {
            getTitle() {
                return (this.playlistId != null || this.smartListId != null) ? this.$t('winelist.editModal.editList') : this.$t('winelist.editModal.newList')
            },
             ...mapGetters(['getVenue', 'getCurrentListTitle'])
        },
    }
</script>
